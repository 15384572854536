<template>
    <div class="col-lg-4 col-md-6">
        <div class="blog-sidebar-one">

            <div class="sidebar-keyword sidebar-categories mb-85 sm-mb-60" v-if="selectedTag != ''">
                <h4 class="sidebar-title text-dark">Keywords</h4>
                <ul class="cleafix">
                    <li><a href="javscript:void(0)">{{ selectedTag }}</a></li>
                </ul>
            </div> <!-- /.sidebar-keyword -->


            <div class="sidebar-categories mb-85 sm-mb-60">
                <h4 class="sidebar-title text-dark">Topics</h4>
                <ul>
                    <li v-for="(category, index) in catList" :key="index">
                        <router-link :to="{ name: 'blogcategory', params: { category: category.slug }, }" :class="{
                            'text-dark': selectedCategory != category.slug,
                            'text-ingomugreen': selectedCategory == category.slug,
                        }" class="fs-20">
                            {{ category.category }} <span><i class="flaticon-right-arrow"></i></span>
                        </router-link>
                    </li>
                </ul>
            </div> <!-- /.sidebar-categories -->
            <div class="sidebar-recent-news mb-85 sm-mb-60">
                <h4 class="sidebar-title text-dark">The Latest</h4>
                <ul>
                    <li v-for="(blogItem, index) in blogShortList" :key="index">
                        <router-link :to="{ name: 'blogdetail', params: { slug: blogItem.slug }, }">
                            <span class="title text-dark">{{ blogItem.title }}</span>
                            <span class="date text-dark">{{ shortFormatDate(blogItem.date) }}</span>
                        </router-link>
                    </li>
                </ul>
            </div> <!-- /.sidebar-recent-news -->
        </div> <!-- /.blog-sidebar-one -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formateDateMixin from "@/mixins/formatDate";

export default {
    name: 'blogsidebar',
    props: {
        selectedCategory: {
            type: String,
            default: ''
        },
        selectedTag: {
            type: String,
            default: ''
        }
    },
    mixins: [formateDateMixin],
    mounted() {
        this.$store.dispatch("getLastNineBlogList");
        this.$store.dispatch("getCategoryList");
    },
    computed: {
        ...mapGetters(["blogItem", "blogShortList", "catList"])
    }
}
</script>

<style scoped>
.mb-25 {
    margin-bottom: 25px;
}

.fs-20 {
    font-size: 20px !important;
}
</style>