const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export default {
    methods: {
        formatDate(timestamp) {
          try {
            let myDate = new Date(timestamp.seconds * 1000);
            return myDate.toGMTString();
          } catch (e) {
            // console.log(e);
          }
        },
        shortFormatDate(timestamp) {
          try {
            let myDate = new Date(timestamp.seconds * 1000);
            return `${myDate.getDate()} ${monthNames[myDate.getMonth()]}, ${myDate.getFullYear()}`;
          } catch (e) {
            // console.log(e);
          }
        },
        shortNormalFormatDate(timestamp) {
          try {
            let myDate = new Date(timestamp.seconds * 1000);
            return `${myDate.getMonth() + 1}/${myDate.getDate()}/${myDate.getFullYear()}`;
          } catch (e) {
            // console.log(e);
          }
        },
        annualExpireDate() {
          try {
            let newDate = new Date()
            newDate.setFullYear(newDate.getFullYear() + 1)
            return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
          } catch (e) {
            console.log(e);
          }
        },
        semiExpireDate() {
          try {
            let newDate = new Date()
            newDate.setFullYear(newDate.getFullYear(), newDate.getMonth() + 6)
            return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
          } catch (e) {
            console.log(e);
          }
        }
    }    
}